import React from "react";
import { Button, Popover, PopoverHeader, PopoverBody } from "reactstrap";
import { Route, Redirect } from "react-router";
import moment from "moment";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import _ from "underscore";
import { PromotionCalendarGA } from '../../../../utils/GA/GAUtils';
import { getBrowserTimeZone } from "../../../../utils/valueFormatters";

class PopoverItem extends React.Component {
  constructor(props) {
    super(props);
    this.toggle = this.toggle.bind(this);
    this.state = {
      popoverOpen: false,
    };
  }

  toggle() {
    this.setState({
      popoverOpen: !this.state.popoverOpen,
    });
  }
  getData = (val) => {
    console.log("Campaing Start date and enddate",val)
    localStorage.setItem("campaign_ly_start_date",val.ly_start_date)
    localStorage.setItem("campaign_ly_end_date",val.ly_end_date)
    this.props.onTabClick(val);
  };
  copyCampaignData = (
    campaignId,
    startDate,
    endDate,
    name,
    lyStartDate,
    lyEndDate
  ) => {
    this.props.onClickCopyAdgroup(
      campaignId,
      startDate,
      endDate,
      name,
      lyStartDate,
      lyEndDate
    );
  };
  submitGA(action,label){
    PromotionCalendarGA({ action, label});
  }
  render() {
    let startDateFormat = new Date(this.props.info.start_date),
      startDate = this.props.info.start_date,
      endDate = this.props.info.end_date,
      todayDate = new Date(),
      copyClassName = "btn btn-outline-primary btnCalendarPopover";
    // startDateFormat < todayDate
    //   ? "btn btn-outline-primary btnCalendarPopover no-pointer-event"
    //   : "btn btn-outline-primary btnCalendarPopover";

    return (
      <div
        id={"Popover-" + this.props.id}
        className="doticons"
        style={{
          width: "100%",
          position: "relative",
          zIndex: "100",
          border: "none !important",
        }}
        onClick={() => this.submitGA("Click", "Clicked_CampaignButton")}
        //onClick={() => this.getData(this.props.info)}
      >
        <span className="mr-1" style={{ cursor: "pointer" }}>
          {/* <span style={{ margin: "5px 0 0 0"}}>
            <i className="fa fa-ellipsis-v" aria-hidden="true" />
          </span> */}
        </span>
        <div className="popoverDivMain" style={{ border: "none !important" }}>
          <Popover
            className="popover33"
            placement={this.props.item.placement}
            isOpen={this.state.popoverOpen}
            target={"Popover-" + this.props.id}
            trigger="legacy"
            toggle={this.toggle}
          >
            <div id="popover-content">
              <div className="divPopover">
                <div className="row">
                  <div className="col-md-4">
                    <p className="popovertext">Name&nbsp;&nbsp;&nbsp;&nbsp;:</p>
                  </div>
                  <div className="col-md-8">
                    {/* <p className="popovertext1">{this.props.info.name}</p> */}
                    <p className="popovertext1">{`${moment(this.props.info.start_date).format("MM/DD/YYYY")} - ${moment(this.props.info.end_date).format("MM/DD/YYYY")}` }</p>
                  </div>
                </div>
                <div className="row" >
                  <div className="col-md-5">
                    <p className="popovertext">Start Date:</p>
                  </div>
                  <div className="col-md-5 campaign-start-date" >
                    <p className="popovertext1">
                      {moment(this.props.info.start_date).format("MM/DD/YYYY")}
                      {/* {getBrowserTimeZone(this.props.info.start_date,"MM/DD/YYYY")} */}
                    </p>
                  </div>
                </div>
                <div className="row">
                  <div className="col-md-5">
                    <p className="popovertext">End Date:</p>
                  </div>
                  <div className="col-md-5 campaign-start-date" >
                    <p className="popovertext1">
                      {moment(this.props.info.end_date).format("MM/DD/YYYY")}
                      {/* {getBrowserTimeZone(this.props.info.end_date,"MM/DD/YYYY")} */}
                    </p>
                  </div>
                </div>
                <div className="buttonbottom1">
                  <button
                    id="popoverButton"
                    type="button"
                    className="btn btn-outline-primary btnCalendarPopover"
                    style={{ marginLeft: "-5px" }}
                    disabled={this.props.disableDeeperOfferBtn || 
                      this.props.info.status === 4 ? true : false}
                    onClick={() => {
                      this.getData(this.props.info);
                      this.submitGA("Click", "Clicked_DetailsButton")
                    }}
                  >
                    Details
                  </button>
                  <button
                    id="popoverButtonEdit"
                    type="button"
                    className={copyClassName}
                    style={{ marginLeft: "10px" }}
                    disabled={this.props.disableDeeperOfferBtn ||
                      localStorage.getItem("role") === "2" ||
                      localStorage.getItem("role") === "5" 
                      //localStorage.getItem("role") === "6"
                        ? true
                        : false
                    }
                    onClick={() => {
                      this.copyCampaignData(
                        this.props.info.id,
                        startDate,
                        endDate,
                        this.props.info.name,
                        this.props.info.ly_start_date,
                        this.props.info.ly_end_date
                      );
                      this.submitGA("Click", "Clicked_EditButton");
                    }}
                  >
                    Edit
                  </button>
                </div>
              </div>
            </div>
          </Popover>
        </div>
      </div>
    );
  }
}

class PopoverExampleMulti extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      popovers: [
        {
          placement: "bottom",
          text: "Left",
        },
      ],
    };
  }

  render() {
    return (
      <div>
        {this.state.popovers.map((popover, i) => {
          return (
            <PopoverItem
              key={i}
              item={popover}
              id={this.props.id}
              info={this.props.promoInfo}
              showPromotionMain={this.props.showPromotionMain}
              onTabClick={this.props.onTabClick}
              showPromotionMainWithValues={
                this.props.showPromotionMainWithValues
              }
              onClickCopyAdgroup={(
                campaignId,
                startDate,
                endDate,
                name,
                lyStartDate,
                lyEndDate
              ) => {
                this.props.onClickCopyAdgroup(
                  campaignId,
                  startDate,
                  endDate,
                  name,
                  lyStartDate,
                  lyEndDate
                );
              }}
              disableDeeperOfferBtn={this.props.disableDeeperOfferBtn}
            />
          );
        })}
      </div>
    );
  }
}
const mapStateToProps = state => ({
  disableDeeperOfferBtn: state.userDataReducer.disableDeeperOfferBtn,
});
export default connect(mapStateToProps)(withRouter(PopoverExampleMulti));
